import {ButtonDefault} from "../components/atoms/ButtonDefault";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../provider/AuthProvider";

const NotFound = () => {
    const navigate = useNavigate();
    // @ts-ignore
    const { token } = useAuth();

    return (
        <div className='m-auto'>
            <span className='font-extrabold text-6xl'>404</span>
            <div className='py-4'>
                <div className='text-2xl'>Vous êtes perdu(e)?</div>
                <div className='text-2xl'>Pas de panique!</div>
            </div>
            <div className='flex flex-col'>
                <ButtonDefault type='button' handleClick={() => navigate('/')}>
                    Retournez à la page d'accueil
                </ButtonDefault>
                {!token && (
                    <ButtonDefault type='button' handleClick={() => navigate('/login')}>
                        Se connecter
                    </ButtonDefault>
                )}
            </div>
        </div>
    )
};

export default NotFound;