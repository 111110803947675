import { Select, Option } from "@material-tailwind/react";

interface SelectOptionProps {
    value: any;
    label: string;
}

interface SelectDefaultProps {
    options: SelectOptionProps[];
    handleChange: (e: any) => void;
    label: string;
}

export function SelectDefault({ options, handleChange, label }: SelectDefaultProps) {
    return (
        <div className="w-72">
            <Select
                size='md'
                value={options[0].value}
                onChange={(e: any) => handleChange(e)}
                placeholder='test'
                label={label}
            >
                {options.map((option: any) => (
                    <Option
                        value={option.value}
                        key={option.value}
                    >
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
}