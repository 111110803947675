import {createContext, ReactElement, useContext, useMemo, useState} from "react";

// @ts-ignore
const AuthContext = createContext();

const AuthProvider = ({ children }: { children: ReactElement }) => {
    const [token, setToken_] = useState<string|null>(
        localStorage.getItem('token') ?? null
    );

    const setToken = (newToken: string|null) => {
        if (newToken) {
            localStorage.setItem('token', newToken);
        } else  if (localStorage.getItem('token') && null === newToken) {
            localStorage.removeItem('token')
        }

        setToken_(newToken);
    };

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;