import React from "react";
import {
    Navbar,
    Typography,
    Button,
} from "@material-tailwind/react";
import {useAuth} from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";

export const NavbarDefault = () => {
    // @ts-ignore
    const { token, setToken } = useAuth();
    const navigate = useNavigate();

    const logout = () => {
        setToken(null);
        navigate("/login");
    };

    const navList = (
        <ul className="mt-2 mb-2 flex flex-col gap-2">
            <Typography
                placeholder='text'
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 cursor-pointer text-sm italic"
                onClick={() => navigate("/todo-lists")}
            >
                    Les listes
            </Typography>
        </ul>
    );

    return <Navbar placeholder='text' className="sticky top-0 z-10 h-max max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
            <Typography
                placeholder='text'
                className="mr-4 cursor-pointer py-1.5 text-2xl font-bold"
                onClick={() => navigate("/")}
            >
                Ma TodoList
            </Typography>
            <div className="flex items-center gap-4">
                <div className="mr-4 block">{navList}</div>
                <div className="flex items-center gap-x-1">
                    {!token ? (
                        <Button
                            placeholder='text'
                            variant="gradient"
                            size="sm"
                            className="inline-block"
                            onClick={() => navigate("/login")}
                        >
                            <span>Se connecter</span>
                        </Button>
                    ) : (
                        <Button
                            placeholder='text'
                            variant="gradient"
                            size="sm"
                            className="inline-block"
                            onClick={logout}
                        >
                            <span>Se déconnecter</span>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    </Navbar>;
}