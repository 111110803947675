import { Button } from "@material-tailwind/react";

interface ButtonDefaultProps {
    children: string;
    type: "button" | "submit" | "reset";
    loading?: boolean;
    handleClick?: () => void;
}

export function ButtonDefault({ children, type, loading = false, handleClick }: ButtonDefaultProps) {
    return <Button
        className='my-2 w-fit whitespace-nowrap'
        loading={loading}
        type={type}
        placeholder='test'
        onClick={handleClick}
    >
        {children}
    </Button>;
}