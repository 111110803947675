import ArrowLeft from "../icons/ArrowLeft";

interface BackLinkProps {
    text?: string;
    handleClick?: () => void;
}

const BackLink = ({ text = 'Retour', handleClick }: BackLinkProps) => {
    return (
        <div className='flex items-center m-2 bg-gray-200 hover:bg-gray-300 rounded-2xl px-4 py-1 w-fit cursor-pointer' onClick={handleClick}>
            <ArrowLeft />
            <span className='ml-4 text-[.9rem] italic'>{text}</span>
        </div>
    )
};

export default BackLink;