import {ButtonDefault} from "../components/atoms/ButtonDefault";
import {useNavigate} from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className='h-full flex flex-col items-center justify-center'>
            <h1 className='font-extrabold text-5xl my-4'>
                Gérez Vos Tâches Facilement avec Notre Application de Todo List
            </h1>
            <div className='text-lg italic my-4'>
                Bienvenue sur notre plateforme de gestion de tâches innovante !
                Simplifiez votre vie quotidienne en organisant vos tâches,
                en suivant votre progression et en restant toujours au top de votre productivité.
                Avec notre application conviviale, vous pouvez créer, modifier et marquer vos tâches terminées en un clin d'œil.
                Ne laissez plus aucune tâche importante passer entre les mailles du filet.
                Rejoignez-nous dès maintenant et commencez à accomplir plus avec moins de stress !
            </div>
            <ButtonDefault
                type='button'
                handleClick={() => navigate('/todo-lists')}
            >
                Accéder aux listes
            </ButtonDefault>
        </div>
    );
};

export default Home;