import {
    List,
    ListItem,
    ListItemSuffix,
    Card,
    IconButton,
} from "@material-tailwind/react";
import Trash from "../icons/Trash";
import {deleteTodoTask} from "../../api";
import {toast} from "react-toastify";
import moment from "moment/moment";

interface TodoTaskItemsProps {
    id: number;
    name: string;
    isOwner: boolean;
    email: string;
    createdAt: string;
}

interface TodoTasksItemsProps {
    tasks: TodoTaskItemsProps[];
    setTasks: any;
}

export function TodoTaskItems({ tasks, setTasks }: TodoTasksItemsProps) {
    const handleDelete = (id: number) => {
        deleteTodoTask(id)
            .then((response) => {
                setTasks((prev: TodoTaskItemsProps[]) => prev.filter((task: TodoTaskItemsProps) => task.id !== id));

                toast.success('Liste supprimée avec succès !');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <Card placeholder='test' className="w-96 mx-auto mt-4 max-h-96 overflow-auto">
            <List placeholder='test'>
                {tasks.map((task) => (
                    <ListItem
                        key={task.id}
                        id={`list-${task.id}`}
                        placeholder='test'
                        ripple={false}
                        className="py-1 pr-1 pl-4 cursor-auto hover:bg-none! focus:bg-none! active:bg-none!"
                    >
                        <div
                            className="flex flex-col pointer-events-none p-2 rounded-md cursor-pointer w-full"
                        >
                            <span className='text-lg'>{task.name}</span>
                            <span className='text-gray-500 text-[.8rem] font-bold'>Créée par {task.email}</span>
                            <span
                                className='text-gray-500 text-sm text-[.8rem] italic'>Le {moment(task.createdAt).format('LLLL')}
                            </span>
                        </div>
                        {task.isOwner && (
                            <ListItemSuffix placeholder='test' className='flex ml-2'>
                                <IconButton
                                    placeholder='test'
                                    variant="text"
                                    color="blue-gray"
                                    onClick={() => handleDelete(task.id)}
                                >
                                    <Trash />
                                </IconButton>
                            </ListItemSuffix>
                        )}
                    </ListItem>
                ))}
            </List>
        </Card>
    );
}