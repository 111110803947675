import AuthProvider from "./provider/AuthProvider";
import Routes from "./routes";
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {NavbarDefault} from "./components/organisms/Navbar";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <NavbarDefault />
        <div className="App">
          <Routes />
        </div>
      </BrowserRouter>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
