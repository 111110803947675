import { useAuth } from "../provider/AuthProvider";
import Login from "../pages/Login";
import TodoList from "../pages/TodoList";
import {Routes as MultipleRoute, Route} from "react-router-dom";
import NotFound from "../pages/NotFound";
import TodoTask from "../pages/TodoTask";
import Home from "../pages/Home";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { pathToRegexp } from 'path-to-regexp';

const Routes = () => {
    // @ts-ignore
    const { token } = useAuth();
    const location = useLocation();

    const routesForPublic = [
        {
            path: "/",
            element: <Home />,
        },
    ];

    const routesForAuthenticatedOnly = [
        {
            path: "/todo-lists/:id",
            element: <TodoTask />,
        },
        {
            path: "/todo-lists",
            element: <TodoList />,
        },
    ];

    const routesForNotAuthenticatedOnly = [
        {
            path: "/login",
            element: <Login />,
        },
    ];

    if (!token) {
        const isProtectedRoute = routesForAuthenticatedOnly.some(route => {
            const routePath = pathToRegexp(route.path);
            return routePath.test(location.pathname);
        });

        if (isProtectedRoute) {
            return <Navigate to="/login" />;
        }
    }

    return <MultipleRoute>
        {routesForPublic.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
        ))}
        {!token ? (
            routesForNotAuthenticatedOnly.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
            ))
        ) : (
            routesForAuthenticatedOnly.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
            ))
        )}
        <Route path='*' element={<NotFound />} />
    </MultipleRoute>
};

export default Routes;