import { useNavigate } from "react-router-dom";
import { useAuth } from "../provider/AuthProvider";
import { login } from "../api";
import { toast } from 'react-toastify';
import {InputDefault} from "../components/atoms/InputDefault";
import {ButtonDefault} from "../components/atoms/ButtonDefault";
import {TitleDefault} from "../components/atoms/TitleDefault";

import {
    Card,
    CardBody,
    Typography,
} from "@material-tailwind/react";
import {useState} from "react";

interface LoginDataInterface {
    username: string;
    password: string;
}

const Login = () => {
    // @ts-ignore
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const handleLogin = (data: LoginDataInterface) => {
        setLoading(true);

        login(data)
            .then((res) => {
                if (res.data?.token) {
                    setToken(res.data.token);
                    navigate("/todo-lists", { replace: true });
                    toast.success('Connexion réussie');
                }
            })
            .catch((err) => {
                if (
                    err.response.data.message === 'Invalid credentials.'
                    && err.response.status === 401
                ) {
                    toast.error('Identifiants incorrects');
                } else {
                    toast.error(err.response.data.message);
                }

                setLoading(false);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data: LoginDataInterface = {
            username: formData.get('email')?.toString() ?? '',
            password: formData.get('password')?.toString() ?? '',
        };

        handleLogin(data);
    };

    return <div className='h-full w-full flex flex-col'>
        <TitleDefault>Page de connexion</TitleDefault>
        <Card placeholder='test' className="mt-6 w-96 items-center m-auto">
            <CardBody placeholder='test'>
                <Typography placeholder='test' variant="h5" color="blue-gray" className="mb-2 text-start">
                    Veuillez vous connecter
                </Typography>
                <form onSubmit={handleSubmit} className='flex flex-col items-center'>
                    <InputDefault label='Email' type='email' name='email' />
                    <InputDefault label='Mot de passe' type='password' name='password' />
                    <ButtonDefault type='submit' loading={loading}>Se connecter</ButtonDefault>
                </form>
            </CardBody>
        </Card>
    </div>;
};

export default Login;