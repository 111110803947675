import {
    List,
    ListItem,
    ListItemSuffix,
    Card,
    IconButton,
} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";
import {InputDefault} from "../atoms/InputDefault";
import Trash from "../icons/Trash";
import Edit from "../icons/Edit";
import {deleteTodoList, editTodoList} from "../../api";
import {toast} from "react-toastify";
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');

interface TodoListItemsProps {
    id: number;
    name: string;
    isOwner: boolean;
    email: string;
    createdAt: string;
}

interface TodoListsItemsProps {
    lists: TodoListItemsProps[];
    setLists: any;
}

export function TodoListItems({ lists, setLists }: TodoListsItemsProps) {
    const navigate = useNavigate();

    const handleFocus = (id: number) => {
        const item = document.getElementById(`todoList-${id}`);

        if (item) {
            item.focus();
        }
    };

    const handleEdit = (e: any, id: number) => {
        editTodoList(id, { name: e.target.value })
            .then((response) => {
                toast.success('Liste modifiée avec succès !');
            })
            .catch((error) => {
                toast.error('Erreur lors de la modification de la liste');
            });
    };

    const handleDelete = (id: number) => {
        deleteTodoList(id)
            .then((response) => {
                setLists((prev: TodoListItemsProps[]) => prev.filter((list: TodoListItemsProps) => list.id !== id));

                toast.success('Liste supprimée avec succès !');
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    return (
        <Card placeholder='test' className="w-96 mx-auto mt-4 max-h-96 overflow-auto">
            <List placeholder='test'>
                {lists.map((list) => (
                    <ListItem
                        key={list.id}
                        id={`list-${list.id}`}
                        placeholder='test'
                        ripple={false}
                        className="py-1 pr-1 pl-4 cursor-auto hover:bg-none! focus:bg-none! active:bg-none!"
                    >
                        <div
                            className="hover:bg-gray-100 p-2 rounded-md cursor-pointer w-full"
                            onClick={() => navigate(
                                `/todo-lists/${list.id}`,
                                { state: { name: list.name } },
                            )}
                        >
                            <div className='pointer-events-none flex flex-col'>
                                <InputDefault
                                    defaultValue={list.name}
                                    label='Liste'
                                    type='text'
                                    name={`todoList-${list.id}`}
                                    id={`todoList-${list.id}`}
                                    onFocusOut={(e: any) => handleEdit(e, list.id)}
                                />
                                <span className='text-gray-500 text-[.8rem] font-bold'>Créée par {list.email}</span>
                                <span className='text-gray-500 text-sm text-[.8rem] italic'>Le {moment(list.createdAt).format('LLLL')}</span>
                            </div>
                        </div>
                        {list.isOwner && (
                            <ListItemSuffix placeholder='test' className='flex ml-2'>
                                <IconButton
                                    placeholder='test'
                                    variant="text"
                                    color="blue-gray"
                                    onClick={() => handleDelete(list.id)}
                                >
                                    <Trash />
                                </IconButton>
                                <IconButton
                                    placeholder='test'
                                    variant="text"
                                    color="blue-gray"
                                    onClick={() => handleFocus(list.id)}
                                >
                                    <Edit />
                                </IconButton>
                            </ListItemSuffix>
                        )}
                    </ListItem>
                ))}
            </List>
        </Card>
    );
}