import {TitleDefault} from "../components/atoms/TitleDefault";
import { TodoListItems } from "../components/molecules/TodoListItems";
import {useEffect, useState} from "react";
import {browseTodoLists, createTodoList} from "../api";
import { toast } from 'react-toastify';
import {LoadingPulse} from "../components/atoms/LoadingPulse";
import {InputDefault} from "../components/atoms/InputDefault";
import {ButtonDefault} from "../components/atoms/ButtonDefault";
import {ALL_OR_MINE_LISTS_FILTER, DEFAULT_LISTS_FILTERS} from "../constants";
import {SelectDefault} from "../components/atoms/SelectDefault";

interface TodoListProps {
    id: number;
    name: string;
    isOwner: boolean;
    email: string;
    createdAt: string;
}

const TodoList = () => {
    const [todoLists, setTodoLists] = useState<TodoListProps[]|null>(null);
    const [filters, setFilters] = useState(DEFAULT_LISTS_FILTERS);

    useEffect(() => {
        browseTodoLists(filters)
            .then((res) => {
                setTodoLists(res.data);
            })
            .catch((err) => {
                toast.error('Erreur lors de la récupération des listes de tâches');
            });
    }, [filters]);

    const handleCreateList = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            name: formData.get('newList')?.toString() ?? ''
        };

        createTodoList(data)
            .then((res) => {
                const todoList: TodoListProps = res.data?.todoList

                setTodoLists((prev: any) => [...prev, todoList]);
                toast.success('Liste créée avec succès');
            })
            .catch((err) => {
                toast.error(err.response?.data?.message ?? 'Erreur lors de la création de la liste');
            })
            .finally(() => {
                e.target.reset();
            });
    };

    const handleSelectFilter = (selectValue: string) => {
        setFilters(prev => ({ ...prev, onlyMine: selectValue === 'true' }));
    };

   return <div className='h-full w-full flex flex-col'>
       <TitleDefault>Todo Listes</TitleDefault>
       <div className='flex flex-col items-center'>
           <SelectDefault label='Listes affichées' options={ALL_OR_MINE_LISTS_FILTER} handleChange={handleSelectFilter} />
           <form onSubmit={handleCreateList} className='flex'>
               <InputDefault classNames='mr-2 !w-40' label='Ajouter une liste' type='text' name='newList'/>
               <ButtonDefault type='submit'>Créer une liste</ButtonDefault>
           </form>
       </div>
       <hr className='w-1/2 mx-auto my-4 mt-2'/>
       {todoLists === null ? (
           <LoadingPulse classNames='w-96 h-24 mx-auto mt-2'/>
       ) : (
           todoLists.length > 0
               ? <TodoListItems lists={todoLists} setLists={setTodoLists}/>
               : 'Aucune liste pour le moment.'
       )}
   </div>
};

export default TodoList;