import { Typography } from "@material-tailwind/react";
import {ReactElement} from "react";

interface TitleDefaultProps {
    children: string | ReactElement;
}
export const TitleDefault = ({ children }: TitleDefaultProps) => {
    return (
        <Typography placeholder='test' variant="h3" className='my-4 text-start'>{children}</Typography>
    );
}