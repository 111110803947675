import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api',
    headers: {
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (
            error.response && error.response.status === 401
            && (
                error.response.data.message === 'Invalid JWT Token'
                || error.response.data.message === 'Expired JWT Token'
            )
        ) {
            localStorage.removeItem('token');
            // Redirection vers la page de connexion en cas d'erreur 401
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default api