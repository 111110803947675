import api from "./apiInterceptor";


interface LoginData {
    username: string;
    password: string
}

export const login = (data: LoginData) => {
    return api.post('/login_check', data);
};

export const getTodoLists = () => {
    return api.get('/lists');
};

export const browseTodoLists = (data: { onlyMine: boolean }) => {
    return api.post(`/lists/browse`, data);
};

export const deleteTodoList = (id: number) => {
    return api.delete(`/lists/${id}`);
};

export const createTodoList = (data: { name: string }) => {
    return api.post('/lists', data);
};

export const editTodoList = (id: number, data: { name: string }) => {
    return api.put(`/lists/${id}`, data);
}

export const getTodoTasks = (id: any) => {
    return api.get(`/lists/${id}/tasks`);
};

export const deleteTodoTask = (id: number) => {
    return api.delete(`/tasks/${id}`);
}

export const createTodoTask = (data: { name: string, todoListId: any }) => {
    return api.post('/tasks', data);
}