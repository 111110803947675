import { Input } from "@material-tailwind/react";

interface InputDefaultProps {
    label: string;
    type: string;
    name: string;
    defaultValue?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    id?: string;
    onFocusOut?: (e: any) => void;
    classNames?: string;
}

export function InputDefault({
        id,
        label,
        type,
        name,
        defaultValue = '',
        disabled = false,
        autoFocus = false,
        onFocusOut,
        classNames = ''
    }: InputDefaultProps)
{
    return (
        <div className={`w-auto my-2 mx-4 min-w-[200px] ${classNames}`}>
            <Input
                id={id}
                autoFocus={autoFocus}
                disabled={disabled}
                defaultValue={defaultValue}
                crossOrigin='anonymous' name={name}
                type={type}
                label={label}
                onBlur={onFocusOut}
            />
        </div>
    );
}