import {useParams, useLocation, useNavigate} from "react-router-dom"
import {TitleDefault} from "../components/atoms/TitleDefault";
import {useEffect, useState} from "react";
import {createTodoTask, getTodoTasks} from "../api";
import {LoadingPulse} from "../components/atoms/LoadingPulse";
import {TodoTaskItems} from "../components/molecules/TodoTaskItems";
import {InputDefault} from "../components/atoms/InputDefault";
import {ButtonDefault} from "../components/atoms/ButtonDefault";
import {toast} from "react-toastify";
import BackLink from "../components/atoms/BackLink";

interface TodoTaskProps {
    id: number;
    name: string;
    isOwner: boolean;
    email: string;
    createdAt: string;
}

const TodoTask = () => {
    const params = useParams();
    const { id } = params;
    const location = useLocation();
    const navigate = useNavigate();
    const listName: string = location.state?.name;
    const [tasks, setTasks] = useState<TodoTaskProps[]|null>(null);

    useEffect(() => {
        getTodoTasks(id)
            .then((res) => {
                setTasks(res.data);
            })
            .catch((err) => {
                toast.error('Erreur lors de la récupération des tâches de la liste');
            });
    }, []);

    const handleCreateTask = (e: any) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            name: formData.get('newTask')?.toString() ?? '',
            todoListId: parseInt(id ?? '0')
        };

        createTodoTask(data)
            .then((res) => {
                const todoList: TodoTaskProps = res.data?.todoTask;

                setTasks((prev: any) => [...prev, todoList]);
                toast.success('Tâche créée avec succès');
            })
            .catch((err) => {
                toast.error(err.response?.data?.message ?? 'Erreur lors de la création de la tâche');
            })
            .finally(() => {
                e.target.reset();
            });
    };

    const handleBack = () => {
        navigate('/todo-lists', { replace: true });
    };

    return (
        <div className='h-full w-full flex flex-col'>
            <div className='flex items-center'>
                <BackLink
                    handleClick={handleBack}
                    text={'Retour aux todo listes'}
                />
                <TitleDefault>
                    <div>Tâche(s) pour la liste <span className='italic'>{listName}</span></div>
                </TitleDefault>
            </div>
            <form onSubmit={handleCreateTask} className='flex w-full justify-center'>
                <InputDefault classNames='mr-8 !w-40' label='Ajouter une tâche' type='text' name='newTask'/>
                <ButtonDefault type='submit'>Créer une tâche</ButtonDefault>
            </form>
            <hr className='w-1/2 mx-auto my-4 mt-2'/>
            {tasks === null ? (
                <LoadingPulse classNames='w-96 h-24 mx-auto mt-2'/>
            ) : (
                tasks.length > 0
                    ? <TodoTaskItems tasks={tasks} setTasks={setTasks}/>
                    : 'Aucune tâche liée à cette liste pour le moment.'
            )}
        </div>
    );
};

export default TodoTask;